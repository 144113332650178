var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"pJhBvoEcfpV8DON7c8Wol"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

import { GRAPHQL_ENDPOINT } from "@/settings";
import { getSentryDsn } from "@/utils/serverUtils";

/* This hook handles adding GraphQL operation name into Sentry breadcrumb */
const beforeBreadcrumb = (breadcrumb, hint) => {
  if (breadcrumb.category === "fetch") {
    const url = breadcrumb.data?.url ?? "";

    if (url.includes(`/${GRAPHQL_ENDPOINT}`)) {
      /* 
        Hint may be undefined for "navigation" type breadcrumb. The hint input contains an array of values:
        The 1st item contains the string of graphql endpoint. The 2nd item contains the HTTP request object 
      */
      const graphQlRequestBody = hint?.input?.[1]?.body;

      const operationName = (() => {
        try {
          const parsedRequestBody = JSON.parse(graphQlRequestBody);
          return parsedRequestBody?.operationName;
        } catch (error) {
          return null;
        }
      })();

      if (operationName) {
        breadcrumb.data.operationName = operationName;
      }
    }
  }

  return breadcrumb;
};

Sentry.init({
  dsn: getSentryDsn(),
  // Ensure only 70% transactions are sent to Sentry
  tracesSampleRate: 0.7,
  // Ensure only 10% of user session replay are recorded
  replaysSessionSampleRate: 0.1,
  // Ensure 100% to always record a replay when an error occurs
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
  ],
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "Authorization error: The access token provided is expired",
  ],
  beforeBreadcrumb,
});
