/* 
  You can use any of these tools to help you compare colors when want to figure out 
  where a new color should be placed:

  https://bl.ocks.org/curran/c130b1d3d68a375c8eb41371f2c2a75b
  https://www.colortools.net/color_compare_colors.html
  https://elektrobild.org/tools/sort-colors 
*/

const colors = {
  white: "#FFFFFF",
  black: "#000000",

  pink: {
    400: "#FFDEDE",
    500: "#FFD6D6",
    600: "#FFBFBF",
    800: "#D84472",
  },

  red: {
    200: "#FF7C7C",
    300: "#FF4242",
    400: "#EB444A",
    420: "#DB493D",
    430: "#C64545",
    450: "#E30A17",
    465: "#E20000",
    500: "#D10101",
    600: "#B50000",
  },

  teal: {
    410: "#DEF4F1",
    480: "#D0EFEB",
    490: "#D1EFEB",
    500: "#CDEFEA",
    700: "#41ADA2",
    800: "#3CAEA3",
    900: "#129185",
    3000: "#083239",
  },

  orange: {
    500: "#FCE4C8",
    700: "#F3CA49",
    800: "#FCB930",
    900: "#FF978E",
    1000: "#FF8900",
    1500: "#ED553B",
  },

  grey: {
    240: "#F7F9FA",
    253: "#F7F7F7",
    275: "#F5F7F8",
    345: "#F0F3F7",
    346: "#F0F2F4",
    348: "#F3F5F6",
    350: "#F0F0F0",
    351: "#F1F1F1",
    360: "#F1F3F5",
    365: "#F2F3F5",
    370: "#F2F2F2",
    375: "#F4F4F4",
    380: "#EFEFEF",
    387: "#EDF0F3",
    395: "#EBEBEB",
    400: "#EAEAEA",
    403: "#E4E7EB",
    405: "#E5E7EB",
    406: "#E6E6E6",
    407: "#E5E5E5",
    408: "#E3E3E3",
    409: "#E1E5E8",
    410: "#E0E1E3",
    420: "#E2E2E2",
    450: "#DFE3E8",
    455: "#DEDEDE",
    465: "#DBDFE5",
    470: "#D9DCE2",
    480: "#D5D5D5",
    500: "#D6D6D6",
    510: "#D4D9E0",
    520: "#D3D3D3",
    600: "#C9C9C9",
    613: "#C8CCD1",
    625: "#CBCBCB",
    630: "#C6C6C6",
    960: "#BBBDBF",
    1000: "#B5B5B5",
    1200: "#ACACAC",
    1300: "#A9B7C2",
    1400: "#A9A9A9",
    1425: "#A7A7A7",
    1450: "#A5A5A5",
    1500: "#A2A2A2",
    1560: "#8E9DAA",
    1579: "#8296A5",
    1580: "#898D90",
    1584: "#8D8D8D",
    1585: "#898989",
    1590: "#868686",
    1600: "#848484",
    1680: "#7A848B",
    1700: "#7E7E7E",
    1710: "#7C7C7C",
    1711: "#7B7B7B",
    1725: "#6A8191",
    1740: "#747474",
    1750: "#727272",
    1755: "#707070",
    1760: "#6C6C6C",
    1780: "#696969",
    1800: "#646464",
    1820: "#606060",
    1890: "#5F6063",
    1895: "#626262",
    1900: "#5F5F5F",
    2000: "#5C5C5C",
    2500: "#4D4D4D",
    2700: "#464646",
    2900: "#3D3E41",
    3000: "#3C3F45",
    3100: "#3E3E3E",
    3200: "#2E3034",
    3300: "#393939",
    3500: "#2F2922",
    3800: "#171717",
  },

  blue: {
    50: "#E4E9EF",
    150: "#CCE8F5",
    400: "#5FB1DD",
    500: "#50C2F5",
    540: "#3AB3E2",
    550: "#17AEFD",
    586: "#00A2C9",
    600: "#0999E2",
    650: "#1899E2",
    700: "#118BC9",
    701: "#0888C9",
    720: "#437FB1",
    1800: "#173F5F",
    2000: "#283959",
    4000: "#6A8191",
  },

  green: {
    1500: "#5ABD53",
    1600: "#4CAF50",
    1650: "#11B255",
    1590: "#62AD46",
    1700: "#1DBF20",
    1900: "#13A83C",
  },

  purple: {
    1000: "#665CAC",
  },

  yellow: {
    100: "#F8F3D6",
    1000: "#8D6C22",
  },
};

export default colors;
